import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

const OtherFooter = () => {
  return (
    <footer className='footer-area'>
      <Box sx={{ px: "5%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-around",
          }}
        >
          <div className='col py-sm-3 py-lg-0'>
            <div
              className='single-footer-widget '
              style={{ textAlign: "center" }}
            >
              <h3>Contact Us</h3>

              <p
                className='location'
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: 0,
                }}
              >
                <img
                  src='images/location.png'
                  alt=''
                  style={{ height: "20px", marginTop: "5px" }}
                />
                <p style={{ fontsize: "11px" }}>
                  216-A, Gautam Nagar Second Floor Above RGM Library New Delhi -
                  110049{" "}
                </p>
              </p>
              <Box
                className='location'
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "flex-start",
                    whiteSpace: "noWrap",
                  },
                }}
              >
                <img src='images/phone.png' alt='' style={{ height: "20px" }} />
                <p style={{ whiteSpace: "noWrap", fontsize: "11px" }}>
                  +91 9162685202 , +1 7036650511
                </p>
              </Box>
            </div>
          </div>

          <div className='col py-sm-3 py-lg-0'>
            <div
              className='single-footer-widget '
              style={{ textAlign: "center" }}
            >
              <h3>Our Policy</h3>

              <p
                type='button'
                data-toggle='modal'
                data-target='#exampleModalLong'
              ></p>
              <p
                type='button'
                data-toggle='modal'
                data-target='#ampleModalLong'
              >
                Privacy Policy
              </p>
              <p
                type='button'
                data-toggle='modal'
                data-target='#termsConditions'
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </Box>
        <div className='copyright-area' style={{ display: "flex" }}>
          <p>Copyright © 2024 Marching Frogs Events. All rights reserved.</p>
          <p className='ml-5'>
            <b> </b>
          </p>
        </div>
      </Box>
    </footer>
  );
};

export default OtherFooter;
