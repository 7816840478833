import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LaxDiv from "../Shared/LaxDiv";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

function Our_events() {
  return (
    <div style={{ backgroundColor: "#F6F4F7", padding: "20px 30px" }}>
      <div className='container'></div>
    </div>
  );
}

export default Our_events;
