import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
// import logo from '.././assets/images/gla_logo.png';
import SponsorsForm from "../Common/SponsorsForm";
import SpeakersForm from "../Common/SpeakersForm";
import RequestToSpeak from "../Common/RequestToSpeak";
import AwardForm from "../Common/AwardForm";
import PreloaderPayment from "../Shared/PreloaderPayment";

class Navigation extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
    open: false,
    openSponsor: false,
    openSpeak: false,
    openPrice: false,
    openAward: false,
    isSubmit: false,
    loading: false,
  };

  updateSponsorData = () => {
    this.setState({ openSponsor: !this.state.openSponsor });
  };

  updateSpeakData = () => {
    this.setState({ openSpeak: !this.state.openSpeak });
  };
  updatePriceData = () => {
    this.setState({ openPrice: !this.state.openPrice });
  };
  updateAward = () => {
    this.setState({ award: !this.state.award });
    // this.state.award = !this.state.award;
    this.setState({ openSponsor: !this.state.openSponsor });
  };
  updateLoading = () => {
    this.state.loading
      ? this.setState({ loading: false })
      : this.setState({ loading: true });
    console.log(this.state.loading);
    console.log("update loading");
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  componentDidUpdate(nextProps) {
    if (this.props.match.path !== nextProps.match.path) {
      // this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    return this.state.loading ? (
      <PreloaderPayment />
    ) : (
      <header id='header' className='header-area'>
        <div id='navbar' className='elkevent-nav'>
          <nav className='navbar navbar-expand-lg navbar-light'>
            <div className='container'>
              <div
                style={{
                  height: "1%",
                  width: "7%",
                  borderRadius: "30px",
                  boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  marginRight: "10%",
                }}
              >
                <Link to='/'>
                  <img
                    src='images/logo.jpg'
                    alt='logo'
                    style={{ height: "80%", width: "100%" }}
                  />{" "}
                </Link>{" "}
              </div>

              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>

              <div
                className={classOne}
                id='navbarSupportedContent'
                style={{ marginLeft: "10px" }}
              >
                <ul className='navbar-nav' style={{ gap: "2%", width: "100%" }}>
                  <li className='nav-item'>
                    <Link
                      exact='true'
                      to='/'
                      onClick={this.toggleOpen}
                      className='nav-link'
                    >
                      Home
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      exact='true'
                      to='/about-us'
                      onClick={this.toggleOpen}
                      className='nav-link'
                    >
                      About
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      to='registeration'
                      className='nav-link'
                      onClick={this.toggleOpen}
                    >
                      Registration
                    </Link>
                    {/* {this.state.openSponsor && < SponsorsForm formName="Want to become a sponsor" updateData={this.updateSponsorData} />} */}

                    {/* {this.state.openPrice && <SpeakersForm formName="Fill the Details Below" updateData={this.updatePriceData} />} */}
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/speakers'
                      className='nav-link'
                      onClick={this.toggleOpen}
                    >
                      Speakers
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      to='/agenda'
                      className='nav-link'
                      onClick={this.toggleOpen}
                    >
                      Agenda
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link
                      to='/upcoming-events'
                      className='nav-link'
                      onClick={this.toggleOpen}
                    >
                      Upcoming Events
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <NavLink
                      to='/contact'
                      className='nav-link'
                      onClick={this.toggleOpen}
                      style={{ margin: 0 }}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  {/* <li className="nav-item" >
                                       
                                        <div className="button-box">
                                        <Link to="#" className="btn btn-primary" onClick={() => this.updateSpeakData()} style={{padding:"7px 5px"}}>Request to Speak</Link>
                                       
                                    </div>
                                    </li>
                                    
                              <li className="nav-item" >
                                         <div className="button-box">
                                        <Link to="#" className="btn btn-primary" onClick={() => this.updateAward()} style={{padding:"7px 5px"}}>
                                            Members
                                        </Link>
                                       
                                    </div>
                                    </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default withRouter(Navigation);
