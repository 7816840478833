import React from "react";
// import { Link } from 'react-router-dom';

class WhyUs extends React.Component {
  render() {
    return <></>;
  }
}

export default WhyUs;
